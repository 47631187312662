
import {
    defineComponent, reactive, ref
} from 'vue';
import { getListForPmSubscription, GetListForPmSubscription } from '@/api/community/subscription';
import { billingCycleMap, autoPayStatusMap } from '@/constant/field-map';

export default defineComponent({
    setup(props, { emit }) {
        const column = [
            { prop: 'IntervalType', label: WordList.RenewType, width: '250' },
            { prop: 'TimeZone', label: WordList.TabelCommunitiesTimeZone, width: '150' },
            { prop: 'StartTime', label: WordList.TmpKeyManageListTanleBeginTime, width: '200' },
            { prop: 'Cycles', label: WordList.RenewTimes, width: '250' },
            { prop: 'NextPayTime', label: WordList.NextApproximatePaydate, width: '150' },
            { prop: 'TotalPrice', label: WordList.OrderTotalPrice, width: '150' },
            { prop: 'Status', label: WordList.MulListUpdateListTanleStatus, width: '150' },
            { prop: 'CreateTime', label: WordList.TabelMessageBoxCreateTime, width: '200' },
            { prop: 'op', label: WordList.CaptureListTanleAction }
        ];

        const formData = reactive({
            row: 10,
            page: 1
        });
        const listData = reactive<GetListForPmSubscription>({
            total: 0,
            row: []
        });
        getListForPmSubscriptionApi();
        function getListForPmSubscriptionApi() {
            getListForPmSubscription(formData, (res: GetListForPmSubscription) => {
                listData.row = res.row;
                listData.total = res.total;
            });
        }
        function toDetail(item: GetListForPmSubscription['row'][0]) {
            emit('changeStep', { type: 'forward', to: 'autoPayInfo', data: item });
        }
        function generateNextPayTime(item: GetListForPmSubscription['row'][0]) {
            if (item.NextPayTime && typeof item.NextPayTime === 'string' && item.NextPayTime.length > 0) {
                return item.NextPayTime.split(' ')[0];
            }
            return item.NextPayTime;
        }
        return {
            column,
            formData,
            listData,
            getListForPmSubscriptionApi,
            toDetail,
            billingCycleMap,
            autoPayStatusMap,
            generateNextPayTime
        };
    }
});

