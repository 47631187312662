// 多个角色/多个地方用到才放到这边
// 字段映射
export const premiumPlanFieldMap = {
    0: WordList.ProperAllTextOff,
    1: WordList.ProperAllTextNormal,
    2: WordList.ProperAllTextExpired,
    3: WordList.Inactived
};
export const activeFieldMap = {
    0: WordList.ProperAllTextInactive,
    1: WordList.ProperAllTextNormal,
    2: WordList.ProperAllTextExpired
};

// 自动续费记录状态
export const autoPayStatusMap = {
    0: WordList.ProperAllTextProcessing,
    1: WordList.ProperAllTextNormal,
    2: WordList.TabelFootCancel,
    3: WordList.TabelFootCancel,
    4: WordList.TabelFootCancel,
    5: WordList.TabelFootCancel,
    6: WordList.PropertyAllTextCompleted
};
// 自动续费计费周期
export const billingCycleMap = {
    0: WordList.AutoRenewMonthly,
    1: WordList.Quarterly,
    2: WordList.Yearly
};
export const autoPayPriceTitle = {
    0: WordList.TotalPriceByMonth,
    1: WordList.TotalPriceByQuarter,
    2: WordList.TotalPriceByYear
};
export const autoPayCancleReasonMap = {
    0: WordList.AutoCancelByAkuvoxCloudPlatform,
    1: WordList.AutoCancelByPayPlatform,
    2: WordList.AutomaticPaymentFailed,
    3: WordList.TheAmountChargedChanges,
    4: WordList.SubscriptionAccountHasDeleted,
    5: WordList.PaidAccessIsClosed,
    6: WordList.ProjectManagePermissClosed
};
